// src/Modals.js

import React, { useEffect, useState, } from 'react'
import { View, StyleSheet, Pressable, Modal, Platform, Image, Linking, ActivityIndicator } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Box, Icon } from 'native-base';
import Pane from './Pane';
import Row from './Row';
import TextInputField from './Input'
import Link from './Link';
import * as Data from './libs/data'
import Text from './Text';
import KeyboardShortcut from './KeyboardShortcut';



export default function SoftwareRegistrationModal(props) {

    if (props.preventAction.software_registration) return;

    let [processing, setProcessing] = useState(false);

    let [isRegistered, setIsRegistered] = useState(Data.is_registered(props.registrationDetails));
    let [email, setEmail] = useState('');
    let [orderid, setOrderid] = useState('');
    let [randomString, setRandomString] = useState('');
    let [eraseBothFields, setEraseBothFields] = useState(false);
    let [license, setLicense] = useState('');



    useEffect(() => {
        setIsRegistered(Data.is_registered(props.registrationDetails));
        if (props.registrationDetails) {
            if (props.registrationDetails.hasOwnProperty('email')) {
                setEmail(props.registrationDetails.email);
            }
            if (props.registrationDetails.hasOwnProperty('orderid')) {
                setOrderid(props.registrationDetails.orderid);
            }

            if (props.registrationDetails.hasOwnProperty('randomString')) {
                setRandomString(props.registrationDetails.randomString);
            }
        }
    }, [props.registrationDetails, props.visible, license]);

    const doActivation = async () => {
        setProcessing(true);
        if (isRegistered) {
            await activate_software('deactivate');
        } else {
            await activate_software('activate');
        }
        setProcessing(false);
    }

    const getOrderid = () => {
        if (isRegistered) {
            return props.registrationDetails.orderid;
        }
    }

    const getEmail = () => {
        if (isRegistered) {
            return props.registrationDetails.email;
        }
    }

    const activate_software = async (state, quiet) => {
        if (orderid == undefined || email == undefined) {
            setEraseBothFields(true);
            return;
        }
        if (orderid == '' || email == '') {
            return;
        }
        quiet = quiet || false;

        let returnMessage = await Data.do_software_registration(state, quiet, orderid, email, randomString,

            {
                setEmail: props.setEmail,
                setOrderid: props.setOrderid,
                registrationDetails: props.registrationDetails,
                setRegistrationDetails: props.setRegistrationDetails,
                setVisible: props.setVisible,
                setEraseBothFields: setEraseBothFields,
                loadPrefsFromJSONStr: props.loadPrefsFromJSONStr,
                visible: props.visible,
                processing: processing,
                setProcessing: setProcessing,

            });
        setProcessing(false);
        if (!quiet) {
            setTimeout(() => {
                alert(returnMessage);
            }, 333);
        }
        return returnMessage;
    }

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.softwareRegistrationModal]}>
                    <Pane
                        id="registration-window"
                        onLayout={() => { }}
                        heading='Software Registration'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        footerStyle={{ marginTop: 20 }}
                        footer={
                            <Box style={styles.actionButtonsWrapper}>
                                <Pressable id="closeRegistration" style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}
                                    onPress={() => { props.setVisible(!props.visible) }}>
                                    <Text style={Constants.styles.secondaryButtonText}>Close</Text>
                                </Pressable>
                                <Pressable id="activate" style={[Constants.styles.actionButton, Constants.styles.primaryButton, (orderid == '' || email == '' || processing) ? Constants.styles.disabledButton : null]} onPress={
                                    () => {
                                        doActivation()
                                    }
                                }>
                                    <>
                                        {processing ?
                                            <ActivityIndicator style={{ alignItems: 'center' }} animating={processing} size="small" color={Constants.colors.primaryColorBG} />
                                            : <Text style={(orderid == '' || email == '') ? Constants.styles.disabledButton : Constants.styles.primaryButton}>{isRegistered ? "Deactivate" : "Activate"}</Text>}
                                    </>
                                </Pressable>
                            </Box>
                        }

                    >
                        <Image nativeID='modalBirdBG' source={require('../assets/fullsize-bird.png')} style={styles.bkgBird} />

                        <Row inModal={1} label="BPP Keycode" style={{ marginRight: Constants.leftOffset }}>
                            <TextInputField style={[Constants.styles.textControl, Constants.styles.uppercase, getOrderid() ? Constants.styles.disabledInput : null]}
                                autoFocus={1} value={orderid} syncWithGetter={1} hiddenType={isRegistered ? 'orderid' : null}
                                disabled={isRegistered || processing ? 1 : 0} getter={orderid} setter={setOrderid} setEraseField={setEraseBothFields} eraseField={eraseBothFields}
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') doActivation();
                                }} placeholder='XXX-YYY-ZZZ' nativeID="orderid" id="orderid" type='orderid'></TextInputField>
                        </Row>
                        <Row inModal={1} label="BPP Email" style={{ marginRight: Constants.leftOffset }}>
                            <TextInputField value={email}
                                hiddenType={isRegistered ? 'email' : null} autoComplete='off'
                                disabled={isRegistered || processing ? 1 : 0} getter={email} setter={setEmail} keyboardType='email-address'
                                style={[Constants.styles.textControl, { textTransform: 'lowercase' }]} id="email"
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') { doActivation(); }
                                }}
                                setEraseField={setEraseBothFields} nativeID="email" syncWithGetter={1} eraseField={eraseBothFields}
                            ></TextInputField>
                        </Row>
                        <Row inModal={1} label="" style={{ marginRight: Constants.leftOffset, marginTop: -1.5 * Constants.leftOffset }}>
                            {window.hasOwnProperty('group_enterprise_enabled') && window['group_enterprise_enabled'] ?
                                <Link id="get-credentials-link" style={styles.link} href="https://bestplanpro.com/help">Having an issue? Contact us.</Link>
                                : <Link id="get-credentials-link" style={styles.link} href="https://bestplanpro.com/customer">Get your credentials</Link>
                            }
                        </Row>

                    </Pane>
                </View>
            </View>
        </Modal >
    </>);
}


export function AboutModal(props) {
    let [defaultState, setDefaultState] = useState('');
    let [eraseBothFields, setEraseBothFields] = useState(false);
    let [activeAutocomplete, setActiveAutocomplete] = useState('');

    const updateDefaultState = (state) => {
        if (state === undefined) {
            state = defaultState;
        }
        if (state.length == 2) {
            state = Data.zysys_get_state_from_abbreviation(state);
        }
        if (props.setter) {
            props.setter(state);
        }
        props.setVisible(!props.visible);
        Data.savePreference('defaultState', state);
    };

    useEffect(() => {
        if (props.getter) setDefaultState(props.getter);
    }, [props.getter])

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.defaultStateModal]}>
                    <Pane
                        id="aboutModal"
                        onLayout={() => { }}
                        heading='About BestPlanPro Online'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                            minHeight: 475, width: '75vw', maxWidth: 706.5,

                        }}
                        footerStyle={{ marginTop: 20, zIndex: -4 }}
                        footer={
                            <Box style={styles.actionButtonsWrapper} >
                                <Pressable id="resetDefaultState" style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}
                                    onPress={() => { updateDefaultState(''); /* '' to erase */ }}>
                                    <Text style={Constants.styles.secondaryButtonText}>Reset</Text>
                                </Pressable>
                                <Pressable id="saveDefaultState" style={[Constants.styles.actionButton, Constants.styles.primaryButton]} onPress={
                                    () => {
                                        updateDefaultState(); f
                                    }
                                }>
                                    <Text style={Constants.styles.primaryButton}>Save</Text>
                                </Pressable>
                            </Box>
                        }

                    >



                        <Image source={require("../assets/about.png")} resizeMode="contain" style={{ height: 475, width: '100%', marginLeft: -1 * Constants.leftOffset }} />
                        <Text style={Constants.styles.bppVersion}>BPP Online v{props.version}, Theme: 2.0</Text>

                    </Pane>
                </View>
            </View>
        </Modal >
    </>);
}

export function CaseLinkModal(props) {
    let [alert, setAlert] = useState('');
    let [processingRW, setProcessingRW] = useState(true);
    let [processingRO, setProcessingRO] = useState(true);

    let rwCopiedLinkAlertText = 'Copied the case link!';
    let roCopiedLinkAlertText = 'Copied the read-only link!';

    useEffect(() => {
        if (alert != '') {
            setTimeout(() => {
                setAlert('');
            }, 5000);
        }
    }, [alert]);

    const generateRWLink = () => {
        setProcessingRW(true);
        props.setTriggerFunction('processRWLink');
    };

    const generateROLink = () => {
        setProcessingRO(true);
        props.setTriggerFunction('processROLink');
    };

    useEffect(() => {
        props.setTriggerFunction('resetLinks');
    }, [props.visible])

    useEffect(() => {
        if (!props.visible) {
            setProcessingRW(false);
            setProcessingRO(false);
        }
    }, [props.visible])

    useEffect(() => {
        if (props.rwCaseLink) {
            setProcessingRW(false);
        }
        if (props.roCaseLink) {
            setProcessingRO(false);
        }
    }, [props.rwCaseLink, props.roCaseLink])

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setTriggerFunction('resetLinks');
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.getLinkModal]}>
                    <Pane
                        id="get-a-link-modal"
                        onLayout={() => { }}
                        heading='Get a Link'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        bottomSpacing={Constants.leftOffset}
                        footerStyle={{ marginTop: 20, zIndex: -4 }}
                        footer={
                            <Box style={styles.actionButtonsWrapper} >
                                <Pressable id="resetDefaultState" style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}
                                    onPress={() => { props.setVisible(!props.visible) }}>
                                    <Text style={Constants.styles.secondaryButtonText}>Close</Text>
                                </Pressable>
                            </Box>
                        }

                    >


                        <View style={{ paddingRight: Constants.leftOffset, marginBottom: Constants.leftOffset }}>
                            <Text style={{ fontWeight: '700', color: Constants.colors.textColor }}>Case Link:</Text>
                            <Text style={{ fontWeight: '500', color: Constants.colors.textColor }}>A link containing all of the details of the client. Useful if saving the data isn't possible.</Text>
                            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 18, flexWrap: 'wrap' }}>
                                <Pressable style={Constants.styles.flexRow} onPress={() => { generateRWLink(); }}>
                                    <Text style={[Constants.styles.link, { width: 140, marginRight: Constants.leftOffset, marginTop: 5, /* corresponds to offsets for the display and padding */ }]}>Get a Case Link</Text>
                                </Pressable>
                                <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter]}>
                                    <View style={[Constants.styles.flex, Constants.styles.verticalCenter]}>
                                        <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { flexGrow: 1 }]}>
                                            {props.rwCaseLink ? <Pressable style={[Constants.styles.flexRow, { marginBottom: -25 }]} onPress={() => { props.clipboard.onCopy(props.rwCaseLink); setAlert(rwCopiedLinkAlertText) }}>
                                                <Text style={[Constants.styles.caseLinkDisplay, alert == rwCopiedLinkAlertText ? { backgroundColor: Constants.colors.actionButton80, fontWeight: '600' } : { fontWeight: '600' }]}>{props.rwCaseLink}</Text>
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.9546 2.95719L14.0405 1.04313C13.8546 0.855344 13.5984 0.75 13.3327 0.75H8.22023C7.11555 0.75 6.22023 1.64562 6.22023 2.75L6.22084 10.75C6.24836 11.8562 7.14523 12.75 8.24836 12.75H14.2484C15.3484 12.75 16.2484 11.85 16.2484 10.75V3.66406C16.2484 3.39906 16.1421 3.14469 15.9546 2.95719ZM14.7484 10.75C14.7484 11.0261 14.5245 11.25 14.2484 11.25H8.22023C7.94405 11.25 7.72023 11.0261 7.72023 10.75V2.75406C7.72023 2.47794 7.94411 2.25406 8.22023 2.25406H12.2202L12.2484 3.75C12.2484 4.30219 12.6962 4.75 13.2484 4.75H14.7202V10.75H14.7484ZM8.74836 14.75C8.74836 15.0261 8.52448 15.25 8.24836 15.25H2.22023C1.94405 15.25 1.72023 15.0261 1.72023 14.75L1.74773 6.75313C1.74773 6.477 1.97161 6.25313 2.24773 6.25313H5.24836V4.75H2.24805C1.14336 4.75 0.248047 5.64531 0.248047 6.75L0.248353 14.75C0.248422 15.8531 1.14398 16.75 2.24836 16.75H8.24836C9.34836 16.75 10.2484 15.85 10.2484 14.75V13.75H8.77648L8.74836 14.75Z" fill="black" />
                                                </svg>
                                            </Pressable> : <ActivityIndicator style={[{ alignItems: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }]} animating={processingRW} size="small" color={Constants.colors.primaryColorBG} />}
                                        </View>

                                        <View style={{ height: Constants.leftOffset, marginTop: 25, justifyContent: 'center', display: 'flex' }}>
                                            {alert == rwCopiedLinkAlertText ?
                                                <Text style={Constants.styles.textControlCaption}>Link Copied</Text>
                                                : null}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>


                        <View style={{ paddingRight: Constants.leftOffset, marginBottom: Constants.leftOffset }}>
                            <Text style={{ fontWeight: '700', color: Constants.colors.textColor }}>Read Only Link: </Text>
                            <Text style={{ fontWeight: '500', color: Constants.colors.textColor }}>A link containing all of the details of the client <Text style={{ fontWeight: '700' }}>AND</Text> the results of the most-recent analysis as well. If this is shared with someone who doesn't have a BestPlanPro subscription, it will be read only. </Text>
                            <Text style={{ fontWeight: '500', color: Constants.colors.textColor }}> </Text>
                            <Text style={{ fontWeight: '500', fontSize: Constants.fontSizes.smallerTextDisclaimer, color: Constants.colors.textColor }}>Note, even if you have competitive analysis mode enabled, only the products selected in your products window will display (excluded plans will not show).</Text>
                            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 18, flexWrap: 'wrap' }}>
                                <Pressable style={Constants.styles.flexRow} onPress={() => { generateROLink(); }}>
                                    <Text style={[Constants.styles.link, { width: 140, marginRight: Constants.leftOffset, marginTop: 5, /* corresponds to offsets for the display and padding */ }]}>Get a Read Only Link</Text>
                                </Pressable>
                                <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter]}>
                                    <View style={[Constants.styles.flex, Constants.styles.verticalCenter]}>
                                        <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter]}>
                                            {props.roCaseLink ? <Pressable style={[Constants.styles.flexRow, { marginBottom: -25 }]}
                                                onPress={() => { props.clipboard.onCopy(props.roCaseLink); setAlert(roCopiedLinkAlertText) }}>
                                                <Text style={[Constants.styles.caseLinkDisplay, alert == roCopiedLinkAlertText ? { backgroundColor: Constants.colors.actionButton80, fontWeight: '600' } : { fontWeight: '600' }]}>{props.roCaseLink}</Text>
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.9546 2.95719L14.0405 1.04313C13.8546 0.855344 13.5984 0.75 13.3327 0.75H8.22023C7.11555 0.75 6.22023 1.64562 6.22023 2.75L6.22084 10.75C6.24836 11.8562 7.14523 12.75 8.24836 12.75H14.2484C15.3484 12.75 16.2484 11.85 16.2484 10.75V3.66406C16.2484 3.39906 16.1421 3.14469 15.9546 2.95719ZM14.7484 10.75C14.7484 11.0261 14.5245 11.25 14.2484 11.25H8.22023C7.94405 11.25 7.72023 11.0261 7.72023 10.75V2.75406C7.72023 2.47794 7.94411 2.25406 8.22023 2.25406H12.2202L12.2484 3.75C12.2484 4.30219 12.6962 4.75 13.2484 4.75H14.7202V10.75H14.7484ZM8.74836 14.75C8.74836 15.0261 8.52448 15.25 8.24836 15.25H2.22023C1.94405 15.25 1.72023 15.0261 1.72023 14.75L1.74773 6.75313C1.74773 6.477 1.97161 6.25313 2.24773 6.25313H5.24836V4.75H2.24805C1.14336 4.75 0.248047 5.64531 0.248047 6.75L0.248353 14.75C0.248422 15.8531 1.14398 16.75 2.24836 16.75H8.24836C9.34836 16.75 10.2484 15.85 10.2484 14.75V13.75H8.77648L8.74836 14.75Z" fill="black" />
                                                </svg>
                                            </Pressable> : <ActivityIndicator style={[{ alignItems: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }]} animating={processingRO} size="small" color={Constants.colors.primaryColorBG} />}
                                        </View>

                                        <View style={{ height: Constants.leftOffset, marginTop: 25, justifyContent: 'center', display: 'flex' }}>
                                            {alert == roCopiedLinkAlertText ?
                                                <Text style={Constants.styles.textControlCaption}>Link Copied</Text>
                                                : null}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Pane>
                </View>
            </View >
        </Modal >
    </>);
}

export function DefaultStateModal(props) {
    let [defaultState, setDefaultState] = useState('');
    let [eraseBothFields, setEraseBothFields] = useState(false);
    let [activeAutocomplete, setActiveAutocomplete] = useState('');

    const updateDefaultState = (state) => {
        if (state === undefined) {
            state = defaultState;
        }
        if (state.length == 2) {
            state = Data.zysys_get_state_from_abbreviation(state);
        }
        if (props.setter) {
            props.setter(state);
        }
        props.setVisible(!props.visible);
        Data.savePreference('defaultState', state);
    };

    useEffect(() => {
        if (props.getter) setDefaultState(props.getter);
    }, [props.getter])

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.defaultStateModal]}>
                    <Pane
                        id="registration-window"
                        onLayout={() => { }}
                        heading='Set The Default State'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        footerStyle={{ marginTop: 20, marginBottom: 20, zIndex: -4 }}
                        footer={
                            <Box style={[styles.actionButtonsWrapper, props.isMobile ? { flexDirection: 'column-reverse', margin: 0, alignItems: 'center', marginTop: Constants.leftOffset, gap: 20 } : null]} >
                                <Pressable id="resetDefaultState" style={[Constants.styles.actionButton, Constants.styles.secondaryButton, props.isMobile ? { width: '70vw' } : null]}
                                    onPress={() => { updateDefaultState(''); /* '' to erase */ }}>
                                    <Text style={Constants.styles.secondaryButtonText}>Reset</Text>
                                </Pressable>
                                <Pressable id="saveDefaultState" style={[Constants.styles.actionButton, Constants.styles.primaryButton, props.isMobile ? { width: '70vw' } : null]} onPress={
                                    () => {
                                        updateDefaultState();
                                    }
                                }>
                                    <Text style={Constants.styles.primaryButton}>Save</Text>
                                </Pressable>
                            </Box>
                        }

                    >


                        <Row inModal={1} label="Default State" containsAutocomplete={1} >
                            <TextInputField

                                syncWithGetter={1} value={defaultState} getter={defaultState} setter={setDefaultState} style={[Constants.styles.textControl, { marginRight: 20 }]}
                                onKeyPress={(e) => {
                                    if (e.key == 'Enter') { updateDefaultState(); }
                                }}
                                confirmAutocompleteBeforeSubmit={1}
                                activeAutocompleteID="defaultState" activeAutocomplete={activeAutocomplete} setActiveAutocomplete={setActiveAutocomplete}
                                autoFocus={1} placeholder="Florida" autocomplete={Constants.StateList.map(function (x) { return x.value; })}
                                setEraseField={setEraseBothFields} eraseField={eraseBothFields}
                            ></TextInputField>

                        </Row>

                    </Pane>
                </View>
            </View >
        </Modal >
    </>);
}

export function EULAModal(props) {
    if (props.isReadonly.current) return;

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                if (props.getter == '') return;
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}>
                <View style={[styles.modalView, styles.defaultStateModal, { maxHeight: 650 }]}>
                    <Pane
                        id="eula-modal"
                        onLayout={() => { }}
                        heading='EULA & Privacy Policy'
                        secondaryIcon={
                            (props.getter == 'accept' || props.getter == 'decline') ?
                                <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                        fill={Constants.colors.captionColor} />
                                </svg>
                                : null
                        }
                        secondaryPress={() => {
                            if (props.getter != 'accept' && props.getter != 'decline') return;
                            props.setVisible(!props.visible);
                        }}
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: Constants.colors.bgcolor,
                        }}
                        footerStyle={{ marginTop: 20, zIndex: -4 }}
                        footer={
                            <Box style={styles.actionButtonsWrapper} >
                                <Pressable id="resetDefaultState" style={[Constants.styles.actionButton, Constants.styles.tertiaryButton]}
                                    onPress={() => {
                                        Linking.openURL("https://bestplanpro.com/legal/refund-policy/");
                                        return;
                                        props.setter('decline');
                                        props.setVisible(!props.visible);
                                        Data.savePreference('eula-acceptance-' + props.eulaVersion, 'decline');
                                    }}>
                                    <Text style={Constants.styles.tertiaryButtonText}>Decline</Text>
                                </Pressable>
                                <Pressable id="saveDefaultState" style={[Constants.styles.actionButton, Constants.styles.tertiaryButton]} onPress={
                                    () => {
                                        props.setter('accept');
                                        Data.savePreference('eula-acceptance-' + props.eulaVersion, 'accept');
                                        props.setVisible(!props.visible);

                                    }
                                }>
                                    <Text style={Constants.styles.tertiaryButtonText}>Accept</Text>
                                </Pressable>
                            </Box>
                        }

                    >
                        <Text style={{ maxHeight: 200, marginTop: -40, marginBottom: 20, paddingRight: Constants.leftOffset, color: Constants.colors.textColor }}>
                            Please read and accept or decline our End-User License Agreement and our Privacy Policy. By using BestPlanPro, you agree to these terms. You can also read these documents online at <Link href="https://bestplanpro.com/legal">https://bestplanpro.com/legal</Link>
                        </Text>
                        <Text style={{
                            boxShadow: Constants.boxShadow, borderRadius: Constants.borderRadius, color: Constants.colors.textColor,
                            zIndex: 99999, height: 300, maxHeight: '33vh', overflow: 'scroll', marginRight: Constants.leftOffset, paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10, backgroundColor: Constants.colors.paneColor, borderWidth: 0
                        }}>{props.eulaText}</Text>
                    </Pane>
                </View>
            </View>
        </Modal >
    </>);
}

export function PiracyModal(props) {

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.softwareRegistrationModal]}>
                    <Pane
                        id="piracy-modal"
                        onLayout={() => { }}
                        heading='Piracy Information'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        footer={
                            <Box style={styles.actionButtonsWrapper} >
                                <Pressable id="setPiracyUnderstood" style={[Constants.styles.actionButton, Constants.styles.primaryButton]} onPress={
                                    () => {
                                        props.setter('accept');
                                        Data.savePreference('eula-acceptance-' + props.eulaVersion, 'accept');
                                        props.setVisible(!props.visible);

                                    }
                                }>
                                    <Text style={Constants.styles.primaryButtonText}>I Understand</Text>
                                </Pressable>
                            </Box>
                        }

                    >
                        <Image nativeID='modalBirdBG-piracy' source={require('../assets/fullsize-bird.png')} style={styles.bkgBird} />

                        {/* paddingLeft is sent in the pane */}
                        <View style={{ paddingRight: Constants.leftOffset, textAlign: 'center' }}>
                            <Text>
                                <Text style={[Constants.styles.noticeText]}>BestPlanPro Online is <Text style={[Constants.styles.noticeText, Constants.styles.bold]}>WEB SOFTWARE</Text>.</Text>
                                <Text style={[Constants.styles.noticeText, { display: 'block' }]}>It's linked to your browser once you <Text style={[Constants.styles.noticeText, Constants.styles.bold]}>activate</Text>.</Text>
                                <Text style={{ display: "block", marginTop: Constants.fontSizes.noticeText, marginBottom: Constants.fontSizes.noticeText }}></Text>

                                <Text style={Constants.styles.noticeText}>For Firefox and Chrome users, we strongly recommend our <Link href="https://bestplanpro.com/bpp-online-browser-ext/">Auto-Backup Settings extension</Link>.</Text>
                                <Text style={{ display: "block", marginTop: Constants.fontSizes.noticeText, marginBottom: Constants.fontSizes.noticeText }}></Text>
                                <Text style={Constants.styles.noticeText}>If you use it on more devices than you're authorized to without <Text style={[Constants.styles.noticeText, Constants.styles.bold]}>deactivating</Text>, you'll be locked out.</Text>
                                <Text style={{ display: "block", marginTop: Constants.fontSizes.noticeText, marginBottom: Constants.fontSizes.noticeText }}></Text>
                                <Text style={[Constants.styles.noticeText, Constants.styles.underline]}>You can still run cases on the platform that was most recently activated.</Text>
                            </Text>
                        </View>

                    </Pane>
                </View>
            </View>
        </Modal >


    </>);
}

export function ErrorModal(props) {

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        {props.visible ? <KeyboardShortcut shortcut="Enter" callback={() => { props.setVisible(false); }} /> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.softwareRegistrationModal]}>
                    <Pane
                        id="error-modal"
                        onLayout={() => { }}
                        heading='Error'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        footer={
                            <Box style={styles.actionButtonsWrapper} >
                                <Pressable id="closeErrorModal" style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}
                                    onPress={() => { props.setVisible(!props.visible) }}>
                                    <Text style={Constants.styles.secondaryButtonText}>Close</Text>
                                </Pressable>
                            </Box>

                        }

                    >
                        <Image nativeID='modalBirdBG' source={require('../assets/fullsize-bird.png')} style={[styles.bkgBirdReverse, { top: -127, right: -72 }]} />

                        {/* paddingLeft is sent in the pane */}
                        <View style={{ paddingRight: Constants.leftOffset, textAlign: 'center' }}>
                            <Text style={{ fontWeight: '500' }}>
                                {props.message}
                            </Text>
                        </View>

                    </Pane>
                </View>
            </View>
        </Modal >


    </>);
}

const ModalBackdropClickDetect = (props) => {
    return (
        <Pressable style={{ outline: 'none', position: 'absolute', top: 0, width: '100%', height: '100%' }} onPressOut={(e) => { if (props.onPress) props.onPress(e); else props.onPressOut(e); }}> </Pressable>
    )
}

export function FrameModal(props) {

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}><ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.frameModal]}>
                    <Pane
                        id="piracy-modal"
                        onLayout={() => { }}
                        heading=''
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: Constants.leftOffset }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        noHeaderBorder={1}
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        bottomSpacing={-80}

                    >
                        <iframe style={{ borderRadius: Constants.borderRadius, marginLeft: -1 * Constants.leftOffset, paddingLeft: 0, borderWidth: 0, maxWidth: props.src.width, width: '75vw' }} src={props.src.link} height={props.src.height} />

                    </Pane>
                </View>
            </View>
        </Modal >


    </>);
}

const styles = StyleSheet.create({
    fullWidth: {
        borderRadius: Constants.borderRadius,
        width: '100%',
    },
    frameModal: {
        borderRadius: Constants.borderRadius,
        overflow: 'visible',
        marginLeft: 0,
        paddingLeft: 0,
        maxWidth: 511.5,
    },
    softwareRegistrationModal: {
        borderRadius: Constants.borderRadius,
        overflow: 'visible',
        maxWidth: 511.5,
    },
    defaultStateModal: {
        borderRadius: Constants.borderRadius,
        overflow: 'visible',
        maxWidth: 511.5,
    },
    getLinkModal: {
        borderRadius: Constants.borderRadius,
        overflow: 'visible',
        maxWidth: 611.5,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: Constants.borderRadius,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        boxShadow: Constants.boxShadow,
        zIndex: 999999,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    modal: {
        zIndex: 5001,
        display: 'flex',
    },
    actionButtonsWrapper: {
        flex: 1,
        textAlign: 'right',
        flexDirection: 'row',
        alignContent: 'right',
        alignItems: Platform.OS !== 'web' ? 'center' : 'right',
        justifyContent: Platform.OS !== 'web' ? 'center' : 'right',
        marginBottom: 32,
        marginRight: 42,
        marginTop: 30,
    },



    bkgBird: {
        height: 512 * .65,
        width: 512 * .65,
        position: 'absolute',
        zIndex: -5000,
        margin: 0,
        padding: 0,
        right: -105,
        top: -90,
        opacity: .2,

    },
    bkgBirdReverse: {
        height: 512 * .65,
        width: 512 * .65,
        position: 'absolute',
        zIndex: -5000,
        margin: 0,
        padding: 0,
        left: -105,
        top: -90,
        opacity: .2,
        transform: 'scaleX(-1)',

    },
    fullWidth: {
        borderRadius: Constants.borderRadius,
        width: '100%',
    },
    softwareRegistrationModal: {
        borderRadius: Constants.borderRadius,
        overflow: 'hidden',
        maxWidth: 511.5,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: Constants.borderRadius,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        boxShadow: Constants.boxShadow,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    modalBackdrop: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: Constants.colors.modalBackdrop,
        zIndex: 5000,
        justifyContent: 'center',
        bottom: 0,
    },
    modal: {
        zIndex: 5001,
        display: 'flex',
    },
    actionButtonsWrapper: {
        flex: 1,
        textAlign: 'right',
        flexDirection: 'row',
        alignContent: 'right',
        alignItems: Platform.OS !== 'web' ? 'center' : 'right',
        justifyContent: Platform.OS !== 'web' ? 'center' : 'right',
        marginBottom: 32,
        marginRight: 42,
        marginTop: 30,
    },

    link: {
        marginTop: 23
    }
});
