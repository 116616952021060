import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Image, ImageBackground, Platform, Pressable, useWindowDimensions, Animated, Linking } from 'react-native';
import * as Constants from '../theme';
import { VStack, HStack, Button, IconButton, Icon, NativeBaseProvider, Center, Box, StatusBar } from "native-base";
import { FontAwesome, AntDesign } from '@expo/vector-icons';
import MenuOption from './MenuOption';
import KeyboardShortcut from './KeyboardShortcut';
import Text from './Text';
import * as Data from './libs/data';
import { CheckField } from './Input';

function Menu(props) {



    const style = StyleSheet.create({
        bkgImage: {
            position: 'absolute',
            top: -20,
            bottom: 0,
            right: 0,
            left: 0,
            width: 510,
        }
    });

    const [activeMobileMenuRef, setActiveMobileMenuRef] = useState(null);

    const jumpToTop = () => {
        if (Platform.OS === 'web') {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
            });
        }
    };

    const mobileMenuTriggered = useRef(false);

    let menuRightOffset = (useWindowDimensions().width - 768);
    if (menuRightOffset < 0) menuRightOffset = 0;
    if (menuRightOffset > 100) menuRightOffset = 100;

    const defaultWidth = 59;
    const defaultHeight = 39.24;
    let [width, setWidth] = useState(59);
    let [height, setHeight] = useState(39.24);
    if (props.logoURI != '' && props.logoURI != 'default') {
        Image.getSize(props.logoURI, (width, height) => {
            let scaleFactor = defaultHeight / height;
            setWidth(width * scaleFactor);
            setHeight(height * scaleFactor);
        });
    }

    return (<>

        {props.openMenu != '' ? <Pressable nativeID="mobileMenu" onPress={() => { props.setOpenMenu(''); }} style={[{ cursor: 'auto', }, styles.modalBackdrop]}></Pressable> : null}
        <HStack bg={Constants.colors.headerColor} nativeID='header' px="1" py="3" pl="3" justifyContent="space-between" alignItems="center" style={[styles.header, { backgroundColor: props.headerBGColor }]}>

            <HStack alignItems="center">
                {props.logoURI == 'default' ? <>
                    <Image source={require('../assets/bird.png')} style={{ height: height, width: width, marginRight: 9.5, marginLeft: 50 }} />
                    <Text selectable={false} style={{ color: props.headerTextColor, fontWeight: '500', fontSize: Constants.fontSizes.brandName, }}>
                        Best Plan Pro
                    </Text>
                </> :
                    props.logoURI == ' ' ? <View style={{ height: height, width: width }}></View> :
                        <><Image source={props.logoURI} style={{ height: height, width: width, marginRight: 9.5, marginLeft: 50 }} />
                        </>
                }
                <Image nativeID='headerOverlay' source={require('../assets/bkg.png')} style={[styles.headerBgImage, { backgroundColor: props.headerBGColor }, { opacity: 0.8, maxWidth: useWindowDimensions().width - 120 }]} />
            </HStack>


            {useWindowDimensions().width < Constants.mobileMenuBreakpoint ?
                <View key={`mobile-menu-${props.refreshObjects}`} style={[props.preventAction.show_menu_options ? { display: 'none' } : null]}>
                    <Pressable onPress={() => { jumpToTop(); props.setOpenMenu('mobile'); }}>
                        {props.openMenu == 'mobile' ?
                            <Pressable onPress={() => { props.setOpenMenu(''); }} style={[Constants.styles.flex, { marginTop: 8, justifyContent: 'center', alignItems: 'center', marginRight: Constants.leftOffset }]}>
                                <svg viewBox="0 0 15 15" width="21" height="21"><g stroke={props.headerTextColor} stroke-width="1.2"><path d="M.75.75l13.5 13.5M14.25.75L.75 14.25"></path></g></svg>
                                <Text selectable={false} style={{ color: props.headerTextColor, marginTop: 3 }}>Close</Text>
                            </Pressable>
                            : <View style={[Constants.styles.flex, { marginTop: 3, justifyContent: 'center', alignItems: 'center', marginRight: Constants.leftOffset }]}>
                                <svg width="30" height="30" viewBox="0 0 30 30" aria-hidden="true">
                                    <path stroke={props.headerTextColor} stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
                                <Text selectable={false} style={{ color: props.headerTextColor }}>Menu</Text>
                            </View>
                        }
                    </Pressable>
                    <ExpandedMobileMenu mobileMenuTriggered={mobileMenuTriggered} activeMobileMenuRef={activeMobileMenuRef} setActiveMobileMenuRef={setActiveMobileMenuRef}  {...props} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu} />
                </View>
                : <HStack key={`menu-${props.refreshObjects}`} style={[{ marginRight: menuRightOffset }, props.preventAction.show_menu_options ? { display: 'none' } : null]}>
                    <Pressable onPress={() => { mobileMenuTriggered.current = false; if (props.openMenu == 'data') props.setOpenMenu(''); else props.setOpenMenu('data') }}>
                        <Text selectable={false} style={[styles.menuButton, { color: props.headerTextColor }, props.openMenu == 'data' ? styles.menuButtonOpen : null]} fontSize={Constants.fontSizes.input} title="Data" bgColor={'transparent'} >Data</Text>
                        <DataMenu activeMobileMenuRef={activeMobileMenuRef} setActiveMobileMenuRef={setActiveMobileMenuRef} {...props} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu} />
                    </Pressable>
                    <Pressable onPress={() => { mobileMenuTriggered.current = false; if (props.openMenu == 'settings') props.setOpenMenu(''); else props.setOpenMenu('settings') }}><Text selectable={false} style={[styles.menuButton, { color: props.headerTextColor }, props.openMenu == 'settings' ? styles.menuButtonOpen : null]} fontSize={Constants.fontSizes.input} title="Settings" bgColor={'transparent'} >Settings</Text>
                        <SettingsMenu activeMobileMenuRef={activeMobileMenuRef} setActiveMobileMenuRef={setActiveMobileMenuRef} {...props} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu} />
                    </Pressable>
                    <Pressable onPress={() => { mobileMenuTriggered.current = false; if (props.openMenu == 'help') props.setOpenMenu(''); else props.setOpenMenu('help') }}><Text selectable={false} style={[styles.menuButton, { color: props.headerTextColor }, props.openMenu == 'help' ? styles.menuButtonOpen : null]} fontSize={Constants.fontSizes.input} title="Help" bgColor={'transparent'} >Help</Text>
                        <HelpMenu activeMobileMenuRef={activeMobileMenuRef} setActiveMobileMenuRef={setActiveMobileMenuRef} {...props} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu} />
                    </Pressable>
                    <Pressable onPress={() => { mobileMenuTriggered.current = false; if (props.openMenu == 'profile') props.setOpenMenu(''); else props.setOpenMenu('profile') }} style={[styles.menuButton, props.openMenu == 'profile' ? styles.menuButtonOpen : null]}>
                        <Image source={require('../assets/icon-profile.png')} style={{ height: 32, width: 32 }} />
                        <ProfileMenu activeMobileMenuRef={activeMobileMenuRef} setActiveMobileMenuRef={setActiveMobileMenuRef} {...props} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu} />
                    </Pressable>
                    <Pressable onPress={() => { mobileMenuTriggered.current = false; if (props.openMenu == 'affiliates') props.setOpenMenu(''); else props.setOpenMenu('affiliates') }} style={[props.preventAction.affiliates || (props.preventAction.affiliates_leads) ? { display: 'none' } : null,]}>
                        <Text selectable={false} style={[styles.menuButton, { color: props.headerTextColor }, props.openMenu == 'affiliates' ? styles.menuButtonOpen : null, props.preventAction.affiliates_leads ? { display: 'none' } : null]} fontSize={Constants.fontSizes.input} title="Get Leads" bgColor={'transparent'} >Get Leads</Text>
                        <GetLeadsMenu activeMobileMenuRef={activeMobileMenuRef} setActiveMobileMenuRef={setActiveMobileMenuRef} {...props} openMenu={props.openMenu} setOpenMenu={props.setOpenMenu} />
                    </Pressable>
                </HStack>
            }

        </HStack>
        <KeyboardShortcut shortcut='Escape' callback={() => {
            props.setOpenMenu('');
        }} />
    </>
    );
}
export default Menu;

const ExpandedMobileMenu = (props) => {
    if (props.openMenu == 'mobile') props.mobileMenuTriggered.current = true;
    if (props.openMenu != 'mobile' && props.mobileMenuTriggered.current == false) return;

    let startingOpacity = props.openMenu == 'mobile' ? 0 : 1;
    let slideIn = props.openMenu == 'mobile' ? 400 : 0;
    const animated = new Animated.Value(slideIn);
    const [opacityAnimation] = useState(new Animated.Value(startingOpacity));
    const duration = 1000 * .25;

    useEffect(() => {
        if (props.openMenu == '') {
            Animated.parallel([
                Animated.timing(opacityAnimation, {
                    toValue: 0,
                    duration: duration,
                    useNativeDriver: true,
                }),
                Animated.timing(animated, {
                    toValue: 400,
                    duration: duration,
                    useNativeDriver: true,
                })]).start(() => { props.mobileMenuTriggered.current = false });
        } else {
            Animated.parallel([
                Animated.timing(opacityAnimation, {
                    toValue: 1,
                    duration: duration,
                    useNativeDriver: true,
                }),
                Animated.timing(animated, {
                    toValue: 0,
                    duration: duration,
                    useNativeDriver: true,
                })]).start();
        }
    }, [props.openMenu]);

    return <>

        <Animated.View style={[Constants.styles.menu, {
            width: '80vw',
            height: '100vh',
            right: 0,
            top: Constants.headerHeight,
            position: 'fixed',
            overflowY: 'scroll',
            overflowX: 'hidden',
            flexWrap: 'nowrap',
            paddingBottom: Constants.leftOffset,
            opacity: opacityAnimation,
            transform: [{ translateX: animated }],
        }]}>
            <Pressable onPress={() => { props.setOpenMenu(''); }}
                style={[Constants.styles.menuHeading, { marginTop: Constants.headerHeight * -1.25, opacity: 0.5, alignItems: 'center', fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '500', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }]}>
                <Text selectable={false} style={{ fontWeight: '500' }}>Menu</Text>
                <svg width="24" height="24" style={{ marginRight: 0 }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                        fill={Constants.colors.captionColor} />
                </svg>
            </Pressable>
            <DataMenu {...props} mobile={1} />
            <SettingsMenu {...props} mobile={1} />
            <HelpMenu {...props} mobile={1} />
            <ProfileMenu {...props} mobile={1} />
            <GetLeadsMenu {...props} mobile={1} />
            <View style={{ minHeight: Constants.leftOffset * 5 }}></View>
        </Animated.View>
    </>
}

const ProfileMenu = (props) => {

    if (props.openMenu != 'profile' && !props.mobile) return;

    let [open, setOpen] = useState(!props.mobile);

    let style = props.mobile ? [styles.mobileMenuGroup, { borderBottomWidth: open ? 0 : 1 /* last option needs a bottom border */ }]
        : [Constants.styles.menu, {
            width: 451.7,
            height: props.preventAction.account_preference_management ? 200 : 370.5,
        }];

    if (props.mobile) {
        // useEffect(() => {
        //     if (open) {
        //         props.setActiveMobileMenuRef('profile');
        //     }
        // }, [open]);

        // useEffect(() => {
        //     if (props.activeMobileMenuRef != 'profile') {
        //         setOpen(false);
        //     }
        // }, [props.activeMobileMenuRef]);
    }

    useEffect(() => {
        if (props.rwCaseLink) {
            switch (switchToTheme) {
                case 'modern':
                    return launchModernTheme();
                case 'classic':
                    return launchClassicTheme();
            }
        }
    }, [props.rwCaseLink]);

    const getExistingCase = () => {
        const params = Data.getParams();
        // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
        let value = params.load; // "some_value"
        if (value) {
            return value;
        } else {
            return '';
        }
    }

    const launchModernTheme = () => {
        if (props.rwCaseLink == "") {
            if (Platform.OS === 'web') {
                if (getExistingCase() != '') {
                    window.location.href = `https://case.bestplanpro.com/${getExistingCase()}?theme=modern`;
                    return;
                }
                if (!props.isRegistered) {
                    window.location.href = `https://case.bestplanpro.com/?theme=modern`;
                    return;
                }
            }
        } else {
            if (Platform.OS === 'web') {
                window.location.href = props.rwCaseLink + "?theme=modern";
            } else {
                Linking.openURL(props.rwCaseLink + "?theme=modern");
            }
        }
    }
    const launchClassicTheme = () => {
        if (props.rwCaseLink == "") {
            if (Platform.OS === 'web') {
                if (getExistingCase() != '') {
                    window.location.href = `https://case.bestplanpro.com/${getExistingCase()}?theme=classic`
                    return;
                }
                if (!props.isRegistered) {
                    window.location.href = `https://case.bestplanpro.com/?theme=classic`;
                    return;
                }
            }
        } else {

            if (Platform.OS === 'web') {
                window.location.href = props.rwCaseLink + "?theme=classic";
            } else {
                Linking.openURL(props.rwCaseLink + "?theme=classic");
            }
        }
    }

    let [switchToTheme, setSwitchToTheme] = useState(null);

    return <>
        <View style={style}>

            {props.mobile ?
                <Pressable onPress={() => { setOpen(!open) }}
                    style={[Constants.styles.menuHeading, Constants.styles.mobileMenuHeadingGroup, { overflow: 'hidden', paddingTop: Constants.styles.mobileMenuHeadingGroup.paddingTop - 8, paddingBottom: Constants.styles.mobileMenuHeadingGroup.paddingBottom - 8, alignItems: 'center', fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '500', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' },
                    open ? { alignItems: 'center', paddingBottom: Constants.leftOffset - 8, borderBottomWidth: 1, borderColor: Constants.colors.gray, marginLeft: -1 * Constants.leftOffset, paddingLeft: Constants.leftOffset, marginRight: -1 * Constants.leftOffset, paddingRight: Constants.leftOffset } : null,
                    ]}><Image source={require('../assets/icon-profile.png')} style={{ height: 32, width: 32 }} />
                    <Icon name={open ? 'up' : 'down'} as={AntDesign} />
                </Pressable>
                : null}
            <View style={[!open ? { display: 'none' } : { display: 'flex', flex: 1, flexWrap: 'wrap' }]}>

                <Text selectable={false} style={[Constants.styles.menuHeading, props.mobile ? { marginTop: Constants.leftOffset * 1.5, fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '400', opacity: .7, paddingLeft: Constants.leftOffset * .5 } : null, props.preventAction.account_preference_management ? { display: 'none' } : null,]}>Account Preferences</Text>
                <View style={[props.preventAction.account_preference_management ? { display: 'none' } : null, props.mobile ? { marginBottom: 21 } : { marginBottom: 52 }]}>
                    <MenuOption level={3} style={props.preventAction.software_registration ? { display: 'none' } : null} {...props} onPress={() => { props.setShowSoftwareRegistrationModal(true); props.setOpenMenu(''); }}
                        label="Software Registration" moddedShortcut="r" />
                    <MenuOption level={3} {...props} lastOption={props.hideCustomize ? 1 : 0} onPress={() => { props.setOpenMenu(''); }} link={`https://bestplanpro.com/route_subscription.php?orderid=${encodeURIComponent(props.orderid)}&email=${encodeURIComponent(props.email)}`} label="Manage Subscription" />
                    {props.hideCustomize ? null : <MenuOption level={3} {...props} lastOption={1} lastOptionBorder={1} onPress={() => { props.setOpenMenu(''); }} link={`https://bestplanpro.com/route_subscription.php?customize=1&orderid=${encodeURIComponent(props.orderid)}&email=${encodeURIComponent(props.email)}`} label="Customize BestPlanPro" />}
                </View>
                <Text selectable={false} style={[Constants.styles.menuHeading, props.mobile ? { marginTop: Constants.leftOffset * 1, fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '400', opacity: .7, paddingLeft: Constants.leftOffset * .5 } : null]}>About</Text>
                <View style={{}}>
                    <MenuOption level={3} {...props} onPress={() => { props.setShowEULAModal(true); props.setOpenMenu(''); }} label="Legal" />
                    <MenuOption level={3} {...props} onPress={() => { props.setShowPiracyModal(true); props.setOpenMenu(''); }} label="Piracy Information" />
                    <MenuOption lastOption={1} lastOptionBorder={1} level={3} {...props} onPress={() => { props.setShowAboutModal(true); props.setOpenMenu(''); }} label="About BestPlanPro" />
                </View>
                <View style={props.mobile ? {} : { flexBasis: '100%', width: 0 }}></View>
                <Text selectable={false} style={[Constants.styles.menuHeading, props.mobile ? { marginTop: Constants.leftOffset * 1.5, fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '400', opacity: .7, paddingLeft: Constants.leftOffset * .5 } : null]}>Theme</Text>
                <View style={props.mobile ? { marginBottom: 0 } : { marginBottom: 42 }}>
                    <MenuOption level={3} {...props} onPress={() => { /* props.setOpenMenu('');  */
                        setSwitchToTheme('modern');
                        if (getExistingCase() != '') { return launchModernTheme() }
                        props.setTriggerFunction('processRWLink');
                        let i = setInterval(() => { if (props.rwCaseLink != '') { launchModernTheme(); clearInterval(i) } }, 2000)
                    }} label="Modern Theme" />
                    <MenuOption lastOption={1} lastOptionBorder={1} level={3} {...props} onPress={() => {
                        setSwitchToTheme('classic');
                        if (getExistingCase() != '') { return launchClassicTheme() }
                        props.setTriggerFunction('processRWLink');
                        let i = setInterval(() => { if (props.rwCaseLink != '') { launchClassicTheme(); clearInterval(i) } }, 2000)
                    }} label="Classic Theme" />
                </View>
            </View>
        </View>
    </>
};

const HelpMenu = (props) => {

    if (props.openMenu != 'help' && !props.mobile) return;
    let style = props.mobile ? styles.mobileMenuGroup : [Constants.styles.menu, {
        width: 265.85,
        height: 255.85,
    }];

    let [open, setOpen] = useState(!props.mobile);
    if (props.mobile) {
        // useEffect(() => {
        //     if (open) {
        //         props.setActiveMobileMenuRef('help');
        //     }
        // }, [open]);
        // useEffect(() => {
        //     if (props.activeMobileMenuRef != 'help') {
        //         setOpen(false);
        //     }
        // }, [props.activeMobileMenuRef]);
    }
    return <>
        <View style={style}>
            {props.mobile ?
                <Pressable onPress={() => { setOpen(!open) }}
                    style={[Constants.styles.menuHeading, Constants.styles.mobileMenuHeadingGroup,
                    open ? {
                        alignItems: 'center', paddingBottom: Constants.leftOffset,
                        borderColor: Constants.colors.gray, marginLeft: -1 * Constants.leftOffset, paddingLeft: Constants.leftOffset, marginRight: -1 * Constants.leftOffset, paddingRight: Constants.leftOffset
                    } : null,
                    { alignItems: 'center', fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '500', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }]}>
                    <Text selectable={false} style={[Constants.styles.link, { fontWeight: open ? '700' : '500' }]}>Help</Text>
                    <Icon name={open ? 'up' : 'down'} as={AntDesign} />
                </Pressable>
                : null}
            <View style={[props.mobile ? {} : { marginLeft: -6 }, !open ? { display: 'none' } : null]}>
                <MenuOption level={2} {...props} firstOption={1} onPress={() => { props.setTriggerFunction('saveDataWithPrefs'); props.setOpenMenu(''); }} label="Get Case For Support" />
                <MenuOption level={2} {...props} onPress={() => { props.setFrameModalSRC({ 'link': 'https://manage.kmail-lists.com/subscriptions/subscribe?a=Uai7ZJ&g=XAAHDY', 'width': '600px', 'height': '500px' }); props.setShowFrameModal(true); /*props.setShowEmailSignupModal(true); */ props.setOpenMenu(''); }} label="Get Training via Email" />
                <MenuOption level={2} {...props} onPress={() => { props.setOpenMenu(''); }} link={`https://bestplanpro.com/tutorials`} label="Tutorials" moddedShortcut="t" />
                <MenuOption level={2} {...props} onPress={() => { props.setOpenMenu(''); }} link={`https://bestplanpro.com/help`} label="Support" moddedShortcut="h" />
                <MenuOption level={2} {...props} onPress={() => { props.setOpenMenu(''); }} link={`https://bestplanpro.com/faq`} label="FAQ" moddedShortcut="?" />
                <MenuOption level={2} {...props} onPress={() => { props.setOpenMenu(''); }} link={`https://feedback.bestplanpro.com/`} label="Feature Requests" moddedShortcut="F" />
                <MenuOption level={2} {...props} lastOption={1} onPress={() => { if (confirm("This will reset all of your settings and all of BPP data from your browser.  You'll have to reactivate.  Proceed?")) props.setTriggerFunction('resetAndReload'); props.setOpenMenu(''); }} label="Reset and Reload" />
            </View>
        </View>
    </>
};

const SettingsMenu = (props) => {

    if (props.openMenu != 'settings' && !props.mobile) return;

    // this is the only menu that expands past the left side of the app
    let rightOffset = useWindowDimensions().width - 807;
    if (rightOffset < -120) rightOffset = -120;
    let style = props.mobile ? styles.mobileMenuGroup : [Constants.styles.menu, {
        width: 531.7,
        height: 255.85, // 370.5 old
        right: useWindowDimensions().width < 807 ? rightOffset : 0,
    }, ((useWindowDimensions().width - 807) < -531.7) ? { left: 0 } : null];

    let [open, setOpen] = useState(!props.mobile);
    if (props.mobile) {
        // useEffect(() => {
        //     if (open) {
        //         props.setActiveMobileMenuRef('settings');
        //     }
        // }, [open]);
        // useEffect(() => {
        //     if (props.activeMobileMenuRef != 'settings') {
        //         setOpen(false);
        //     }
        // }, [props.activeMobileMenuRef]);
    }
    return <>
        <View style={style}>
            {props.mobile ?
                <Pressable onPress={() => { setOpen(!open) }}
                    style={[Constants.styles.menuHeading, Constants.styles.mobileMenuHeadingGroup,
                    open ? { alignItems: 'center', paddingBottom: Constants.leftOffset, borderBottomWidth: 1, borderColor: Constants.colors.gray, marginLeft: -1 * Constants.leftOffset, paddingLeft: Constants.leftOffset, marginRight: -1 * Constants.leftOffset, paddingRight: Constants.leftOffset } : null,
                    { fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '500', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }]}>
                    <Text selectable={false} style={[Constants.styles.link, { fontWeight: open ? '700' : '500' }]}>Settings</Text>
                    <Icon name={open ? 'up' : 'down'} as={AntDesign} />
                </Pressable>
                : null}
            <View style={[!open ? { display: 'none' } : { display: 'flex', flex: 1, flexWrap: 'wrap' }]}>
                <Text selectable={false} style={[Constants.styles.menuHeading, props.mobile ? { marginTop: Constants.leftOffset * 1.5, fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '400', marginBottom: 5, opacity: .7, paddingLeft: Constants.leftOffset * .5 } : null]}>Producer Settings</Text>
                <View style={{ marginBottom: 52 }}>
                    <MenuOption style={[props.preventAction.product_selection ? { display: 'none' } : null]} level={3} firstOption={1}{...props} onPress={() => { props.setShowSetAvailableProductsSlideIn(true); props.setOpenMenu(''); }} label="Set Available Products" moddedShortcut="p" />
                    <MenuOption level={3} {...props} onPress={() => { props.setShowDefaultStateModal(true); props.setOpenMenu(''); }} label="Set Default State" moddedShortcut="d" />
                    {/*<MenuOption level={3} {...props} lastOption={1} lastOptionBorder={1} onPress={() => { alert('need to implement this still'); props.setShowMarketerSpreadsheetSlideIn(true); props.setOpenMenu(''); }} label="Show Marketer Spreadsheet" /> */}
                </View>
                <View style={props.mobile ? {} : { flexBasis: '100%', width: 0 }}></View>
                <Text selectable={false} style={[Constants.styles.menuHeading, props.mobile ? { marginTop: Constants.leftOffset * 0, fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '400', marginBottom: 5, opacity: .7, paddingLeft: Constants.leftOffset * .5 } : null]}>General Settings</Text>
                <View style={{}}>
                    <MenuOption level={3} firstOption={1}{...props}
                        label={
                            <CheckField
                                label={"Show Excluded Plans"}
                                checked={props.showExcludedPlans}
                                onChange={(e) => {
                                    let newVal = !props.showExcludedPlans;
                                    props.setShowExcludedPlans(newVal);
                                    Data.savePreference('showExcludedPlans', newVal);
                                }}
                                style={{ marginBottom: 0, marginTop: 0 }}

                            >
                            </CheckField>

                        } />
                    <MenuOption level={3} {...props} label={
                        <CheckField
                            label={"Warn Before Case Reset"}
                            checked={props.warnBeforeClearingData}
                            onChange={(e) => {
                                let newVal = !props.warnBeforeClearingData;
                                props.setWarnBeforeClearingData(newVal);
                                Data.savePreference('warnBeforeClearingData', newVal)
                            }}
                            style={{ marginBottom: 0, marginTop: 0 }}
                        >
                        </CheckField>

                    } />
                    {/* <MenuOption level={3} {...props} onPress={() => { props.setOpenMenu(''); }} label="Show Telesales Indicators" /> */}
                    <MenuOption level={3} style={props.preventAction.import_settings ? { display: 'none' } : null} {...props} onPress={() => { props.setTriggerFunction('loadFile'); props.setOpenMenu(''); }} label="Import Settings" />
                    <MenuOption level={3} lastOption={1} {...props} onPress={() => { props.setTriggerFunction('exportPrefs'); props.setOpenMenu(''); }} label="Export Settings" />
                </View>
            </View>
        </View>
    </>
};

const DataMenu = (props) => {

    if (props.openMenu != 'data' && !props.mobile) return;
    let style = props.mobile ? styles.mobileMenuGroup : [Constants.styles.menu, {
        width: 225.85,
        height: 235.85,
    }];

    let [open, setOpen] = useState(!props.mobile);
    if (props.mobile) {
        // useEffect(() => {
        //     if (open) {
        //         props.setActiveMobileMenuRef('data');
        //     }
        // }, [open]);
        // useEffect(() => {
        //     if (props.activeMobileMenuRef != 'data') {
        //         setOpen(false);
        //     }
        // }, [props.activeMobileMenuRef]);
    }
    return <>
        <View style={style}>
            {props.mobile ? <Pressable onPress={() => { setOpen(!open) }}
                style={[Constants.styles.menuHeading, Constants.styles.mobileMenuHeadingGroup,
                { fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '500', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }]
                }>
                <Text selectable={false} style={[Constants.styles.link, { fontWeight: open ? '700' : '500' }]}>Data</Text>
                <Icon name={open ? 'up' : 'down'} as={AntDesign} />
            </Pressable> : null}
            <View style={[props.mobile ? {} : { marginLeft: -6 }, !open ? { display: 'none' } : null]}>
                <MenuOption level={2} {...props} firstOption={1} onPress={() => { props.setTriggerFunction('saveData'); props.setOpenMenu(''); }}
                    label="Save Case File" moddedShortcut="s" />
                <MenuOption level={2} {...props} onPress={() => { props.setTriggerFunction('loadFile'); props.setOpenMenu(''); }}
                    label="Load Case File" moddedShortcut="o" />
                <MenuOption level={2} {...props} moddedShortcut="!" onPress={() => { props.setTriggerFunction('copyData'); props.setOpenMenu(''); }} label="Copy Case File" />
                <MenuOption level={2} {...props} onPress={() => { props.setTriggerFunction('pasteData'); /*props.setShowEULAModal(true); */ props.setOpenMenu(''); }} label="Paste Case File" />
                <MenuOption level={2} {...props} moddedShortcut="e" onPress={() => { props.setTriggerFunction('emailCase'); props.setOpenMenu(''); }} label="Email Case File" />
                <MenuOption level={2} {...props} lastOption={1} moddedShortcut="l" onPress={() => { props.setTriggerFunction('getLink'); /*props.setShowEULAModal(true); */ props.setOpenMenu(''); }} label="Get A Link" />
            </View>
        </View>
    </>
}

const GetLeadsMenu = (props) => {

    if (props.openMenu != 'affiliates' && !props.mobile) return;
    if (props.preventAction.account_preference_management) return;
    let style = props.mobile ? styles.mobileMenuGroup : [Constants.styles.menu, {
        width: 225.85,
        //height: 235.85,
        height: 200,
    }];

    let [open, setOpen] = useState(!props.mobile);
    if (props.mobile) {
        // useEffect(() => {
        //     if (open) {
        //         props.setActiveMobileMenuRef('affiliates');
        //     }
        // }, [open]);
        // useEffect(() => {
        //     if (props.activeMobileMenuRef != 'affiliates') {
        //         setOpen(false);
        //     }
        // }, [props.activeMobileMenuRef]);
    }
    return <>
        <View style={style}>
            {props.mobile ? <Pressable onPress={() => { setOpen(!open) }}
                style={[Constants.styles.menuHeading, Constants.styles.mobileMenuHeadingGroup,
                { fontSize: Constants.styles.menuHeading.fontSize * .8, fontWeight: '500', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }]
                }>
                <Text selectable={false} style={[Constants.styles.link, { fontWeight: open ? '700' : '500' }]}>Get Leads</Text>
                <Icon name={open ? 'up' : 'down'} as={AntDesign} />
            </Pressable> : null}
            <View style={[props.mobile ? {} : { marginLeft: -6 }, !open ? { display: 'none' } : null]}>
                <MenuOption level={3} {...props} onPress={() => { props.setOpenMenu(''); }} link={`https://bestplanpro.com/lead-marketplace.php?orderid=${encodeURIComponent(props.orderid)}&email=${encodeURIComponent(props.email)}`} label="Inbound Lead Marketplace" />
                <MenuOption level={3} {...props} style={[{
                    fontSize: 8.5,
                    lineHeight: 8.5,
                    fontStyle: 'italic',
                    color: Constants.colors.textColor,

                }, props.mobile ? {} : { marginTop: 10 }]} lastOption={true} label={`
                The Live Client service, conveniently connects you to inbound lead providers, giving you the same high quality leads that only large volume clients usually get.  We are not a lead provider, nor do we endorse any lead provider, or guarantee any leads. We may be compensated by lead providers appearing on the marketplace. `.trim()} />

            </View>
        </View>
    </>
}

let params = Data.getParams();


const styles = StyleSheet.create({
    header: {
        height: Constants.headerHeight,
        backgroundImage: require('../assets/bkg.png'),
        backgroundColor: Constants.colors.headerColor,
        zIndex: 1,
        boxShadow: Constants.boxShadow,

    },
    headerBgImage: {
        height: Constants.headerHeight,
        opacity: .7,
        width: 510,
        overflow: 'hidden',
        position: 'absolute',
        zIndex: -1,
        marginRight: 9.5,
        marginLeft: 'clamp(-15px, 5vw, 100px)',
        backgroundColor: Constants.colors.headerColor,
    },
    menuButton: {
        color: Data.apiTranslationLayer(params.source, 'menuTextColor', Constants.colors.paneColor),
        width: 86,
        height: Constants.headerHeight,
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        borderWidth: 0,
        cursor: 'pointer',
        flex: 1,
        display: 'flex',
    },
    menuButtonOpen: {
        backgroundColor: Constants.colors.linkColor,
    },
    modalBackdrop: {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: Constants.colors.modalBackdrop,
        zIndex: 1,
        justifyContent: 'center',
        overflowY: 'hidden',
    },
    mobileMenuGroup: {
        paddingTop: Constants.styles.menuHeading.marginBottom,
        justifyContent: 'center',
        marginLeft: -1 * Constants.leftOffset,
        paddingLeft: Constants.leftOffset,
        marginRight: -1 * Constants.leftOffset,
        paddingRight: Constants.leftOffset,
        borderTopWidth: 1,
        borderColor: Constants.colors.gray,
        overflowX: 'hidden',
    }
});
