import { View, Text } from 'native-base';
import React, { useEffect, useState } from 'react';
import * as Constants from '../theme'

const SAHProgressBar = (props) => {


    return (
        <View role="progressbar" style={[{ background: Constants.colors.disabledButton, borderRadius: Constants.borderRadius, overflow: 'hidden' }, props.style]} >
            <Text style={[
                {
                    borderRadius: props.progress == 1
                        ? Constants.borderRadius : `${Constants.borderRadius} 0px 0px ${Constants.borderRadius}`,
                    width: `max(40px, min(${props.progress < .10 ? 10 : (props.progress * 100)}%))`,
                    background: Constants.colors.actionButton,
                    textAlign: 'center',
                    color: 'white',
                    fontWeight: '500',
                    transition: `width ${props.completeCriteria ? '0.3s' : '0.1s'} ease-in-out`,
                    whiteSpace: 'nowrap',
                }]}>
                {parseInt(`${props.progress * 100}`)}%
            </Text>
        </View>
    )
};


export default SAHProgressBar;
